
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  
  td, th {
    border: 0;
    border-bottom: 2px solid #f4f5f6;
    text-align: left;
    padding: 20px;
    font-size: .8rem;
  }

  thead th {
    font-weight: normal;
}
  
  tr:nth-child(even) {
    background-color: rgba(0, 150, 136, 0.05);
  }

  .report-th {
    padding: 6px;
    font-size: 12px;
  }

  .report-td {
    padding: 6px;
    font-size: 12px;
  }


  .map-images::-webkit-scrollbar {
    height: 4px;
  } 
  
  /* Track */
  .map-images::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 4px;
  }
  
  /* Handle */
  .map-images::-webkit-scrollbar-thumb {
    background: #14B6A7;
    border-radius: 4px;
  }